export default function Configs(that) {

    const tableColumns = [
        {
            label: "序号",
            prop: "no",
            align: "center",
            width: '70px',
            show: true
        },
        {
            label: "检查项目",
            prop: "itemName",
            align: "center",
            minWidth: 140,
            show: true
        },
        {
            label: "缺损类型",
            prop: "typeName",
            align: "center",
            show: true
        },
        {
            label: "缺损范围",
            prop: "defectArea",
            align: "center",
            show: true
        },
        {
            label: "照片",
            name: "pictureUrl",
            align: "center",
            show: true,
            minWidth: 100,
        },
        {
            label: "备注",
            prop: "remark",
            align: "center",
            show: true
        },
        {
            label: "操作",
            name: 'custom',
            align: "center",
            show: true,
            width: '100px',
        },
    ];

    const formItems = [
        
        {
            label: "检查项目",
            span: 24,
            model: "itemId",
            clearable: true,
            type: "select",
            options: [],
            change: that.getTypeOptions,
        },
        {
            label: "缺损类型",
            span: 24,
            model: "typeId",
            clearable: true,
            type: "select",
            options: [],
        },
        {
            label: "缺损范围",
            span: 24,
            model: "defectArea",
            clearable: true,
            placeholder: '请输入',
        },
        {
            label: "序号",
            span: 24,
            model: "no",
            clearable: true,
            placeholder: '请输入',
            type:'number',
        },
        {
            label: "备注",
            span: 24,
            model: "remark",
            clearable: true,
            type: 'textarea',
            placeholder: '请输入',
            maxLength: 200,
            showLimit: true,
        },
        
    ];
    const formRules = {
        no: [{
            required: true,
            message: "请输入",
            trigger: "blur"
        }],
        itemId: [{
            required: true,
            message: "请选择",
            trigger: "change"
        }],
        typeId: [{
            required: true,
            message: "请选择",
            trigger: "change"
        }],
        defectArea: [{
            required: true,
            message: "请输入",
            trigger: "blur"
        }],
        // pictureUrl: [{
        //     required: true,
        //     message: "请选择",
        //     trigger: "change"
        // }],
    }
    return {
        formItems,
        formRules,
        tableColumns
    };
}
