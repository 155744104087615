<template>
  <div class="report-main">
    <h3
      style="
        padding-top: 15px;
        text-align: center;
        font-weight: 900;
        font-size: 18px;
      "
    >
      桥梁巡检记录标——{{ typeName }}
    </h3>
    <div class="tit-search flex space-between">
      <div class="flex">
        <div class="info-item">
          <span class="label">记录人：</span>
          <span class="value">{{ infoData.recordName }}</span>
        </div>
        <div class="info-item">
          <span class="label">记录时间：</span>
          <span class="value">{{ infoData.recordTime }}</span>
        </div>
      </div>
      <div class="mb-2" style="display: flex">
        <el-button class="btn-primary" @click="goto('')" size="small"
          >新增</el-button
        >
        <el-button size="small" class="back" @click="back"
          ><i class="iconfont icon-fanhui mr-2 align-middle"></i>返回</el-button
        >
      </div>
    </div>
    <div class="div-scroll table-wrapper">
      <c-table
        :index="false"
        :data="infoData.detailList"
        :tableColumns="tableColumns"
        :stripe="true"
        v-loading="pageLoading"
      >
        <template v-slot:pictureUrl="{ row }">
          <div class="picture-cell" v-if="row.pictureUrl">
            <el-image
              v-if="row.pictureUrl"
              style="width: 50px; height: 50px"
              :src="row.pictureUrl"
              :preview-src-list="[row.pictureUrl]"
            ></el-image>
            <div class="mask">
              <i
                class="el-icon-zoom-in"
                title="预览"
                @click="handlePreview(row)"
              ></i>
              <i
                class="el-icon-upload2"
                title="重新上传"
                @click="gotoUpload(row)"
              ></i>
            </div>
          </div>
          <el-button type="text" v-else @click="gotoUpload(row)"
            >点击上传</el-button
          >
        </template>
        <template v-slot:custom="{ row }">
          <span class="opt-detail" style="margin-right: 10px" @click="goto(row)"
            >编辑</span
          >
          <span
            class="opt-detail"
            style="margin-right: 10px"
            @click="handlePageDelete(row)"
            >删除</span
          >
        </template>
      </c-table>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
    >
      <div class="div-scroll" style="max-height: 500px; padding: 0 2%">
        <div v-loading="handleLoading">
          <c-form
            v-if="dialogType == 'form'"
            v-model="handleData"
            ref="cForm"
            :formRules="formRules"
            :formItems="formItems"
            labelWidth="80px"
          >
            <template slot="custom" v-if="!handleData.id">
              <el-form-item label="照片" prop="pictureUrl">
                <el-upload
                  v-loading="isUpld"
                  ref="upload_form"
                  action=""
                  list-type="picture-card"
                  accept="image/jpeg,image/jpg,image/png,image/bmp,image/gif"
                  :file-list="fileList"
                  :on-success="onSuccess"
                  :on-remove="onRemove"
                  :on-error="onError"
                  :on-exceed="onExceed"
                  :on-preview="onPreview"
                  :http-request="(e) => customFormUpload(e)"
                  :limit="1"
                >
                  <i class="el-icon-plus" slot="default"></i>
                </el-upload>
              </el-form-item>
            </template>
          </c-form>
          <el-form v-else :model="handleData" :rules="formRules">
            <el-form-item label="照片" prop="pictureUrl">
              <el-upload
                v-loading="isUpld"
                ref="upload"
                action=""
                list-type="picture-card"
                accept="image/jpeg,image/jpg,image/png,image/bmp,image/gif"
                :file-list="fileList"
                :on-success="onSuccess"
                :on-remove="onRemove"
                :on-error="onError"
                :on-exceed="onExceed"
                :on-preview="onPreview"
                :http-request="(e) => customUpload(e)"
                :limit="1"
                :auto-upload="false"
              >
                <i class="el-icon-plus" slot="default"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 图片预览弹框 -->
    <div
      tabindex="-1"
      class="el-image-viewer__wrapper"
      style="z-index: 9999"
      v-if="dialogVisible1"
    >
      <div class="el-image-viewer__mask"></div>
      <span
        class="el-image-viewer__btn el-image-viewer__close"
        @click="dialogVisible1 = false"
        ><i class="el-icon-close"></i
      ></span>
      <div class="el-image-viewer__canvas">
        <img
          :src="previewSrc"
          class="el-image-viewer__img"
          style="
            transform: scale(1) rotate(0deg);
            margin-left: 0px;
            margin-top: 0px;
            max-height: 100%;
            max-width: 100%;
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
// import export
import MHandle from "@/mixins/MHandle.js";
import MPage from "@/mixins/MPage.js";
import Configs from "./config/detail";
let mPage = MPage({
  pagePath: "",
  pageDelPath: "",
  pageDelsPath: "",
  pageParams: {
    name: "",
    defectTypeName: "",
    type: "",
  },
  pageExportPath: "",
  pageExportParams: {},
});
let mHandle = MHandle({
  handleGetPath: "/inspection/detail",
  handleInsertPath: "/inspection/detail/save",
  handleUpdatePath: "/inspection/detail/update",
  handleDownLoadPath: "",
  handleData: {},
});
export default {
  mixins: [mPage, mHandle],
  data() {
    let { tableColumns, formItems, formRules } = Configs(this);
    return {
      formItems,
      formRules,
      tableColumns,
      navActive: 0,
      dialogVisible: false,
      title: "",
      defaultHandleData: {
        no:'',
        itemId: "",
        typeId: "",
        defectArea: "",
        pictureUrl: "",
        remark: "",
      },
      infoData: {
        recordName: "",
        recordTime: "",
        detailList: [],
      },
      fileList: [],
      dialogVisible1: false,
      previewSrc: "",
      isUpld: false,
      dialogType: "",
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    typeName() {
      return this.type == 1 ? "日常巡检" : "经常巡检";
    },
    id() {
      return this.$route.params.id;
    },
  },
  created() {},
  mounted() {
    this.getItemOptions();
    this.getData();
  },
  methods: {
    back() {
      this.$router.push("/single/inspection/records");
    },
    // 获取数据详情
    getData() {
      this.pageLoading = true;
      this.$http
        .get("/inspection/record/" + this.id)
        .then((res) => {
          if (res.success) {
            this.infoData = res.data || {};
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
        });
    },
    // 获取检查项目选项
    getItemOptions() {
      let params = { type: this.type };
      this.$http.get("/inspection/item/getItemList", { params }).then((res) => {
        if (res.success) {
          let data = res.data || [];
          let options = data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
          this.formItems[0].options = options;
        }
      });
    },
    // 获取缺损类型选项
    getTypeOptions(itemId) {
      this.handleData.typeId = "";
      this.formItems[1].options = [];
      if (!itemId) return;
      let params = { itemId };
      this.$http
        .get("/inspection/item/getDefectTypeList", { params })
        .then((res) => {
          if (res.success) {
            let data = res.data || [];
            let options = data.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            });
            this.formItems[1].options = options;
          }
        });
    },
    goto(data) {
      this.dialogType = "form";
      this.handleData = Object.assign({}, this.defaultHandleData);
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.cForm && this.$refs.cForm.clearValidate();
      });
      if (data) {
        this.title = "编辑";
        this.getTypeOptions(data.itemId);
        this.handleData = Object.assign({}, data);
      } else {
        this.title = "新增";
      }
    },
    gotoUpload(data) {
      this.title = "照片上传";
      this.fileList = [];
      this.dialogType = "upload";
      this.dialogVisible = true;
      this.handleData = Object.assign({}, data);
    },
    // 删除列表项目
    handlePageDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let params = { id: row.id };
        this.$http
          .post("/inspection/detail/delete", null, { params })
          .then((res) => {
            if (res.success) {
              this.$message.success(res.data);
              this.getData(true);
            } else {
              this.$message.erroe(res.msg);
            }
          })
          .catch((err) => {
            this.$message.erroe("操作失败！" + err);
          });
      });
    },
    submit() {
      if (this.dialogType == "form") {
        this.$refs.cForm.submit((data) => {
          if (this.handleData.id) {
            this.handleUpdate();
          } else {
            this.handleData.recordId = this.infoData.id;
            this.handleInsert();
          }
        });
      } else {
        this.$refs.upload && this.$refs.upload.submit();
      }
    },
    handleAfterFn() {
      this.dialogVisible = false;
      this.getData();
    },
    resetFields() {
      this.dialogVisible = false;
    },
    onSuccess(res, file, fileList) {
      if (res.code == this.SUCCESS_CODE) {
        file.url = res.data.url;
        this.fileList = fileList;
      }
    },
    onRemove(file, fileList) {
      this.fileList = [];
      this.handleData.pictureUrl = "";
    },
    onError(err) {
      this.$message.error("上传失败，" + err);
    },
    // 图片上传超出一张时提醒
    onExceed(file, fileList) {
      return this.$message.warning("最多只能上传1张照片，请先删除后上传！");
    },
    customUpload(e) {
      // console.log(e)
      let file = e.file;
      let fileName = new Date().getTime() + "_" + file.name + ".png";
      this.imgUpload(file, fileName);
    },
    customFormUpload(e) {
      let file = e.file;
      // let fileName = new Date().getTime() + "_" + file.name + ".png";
      let formData = new FormData();
      formData.append("file", file);
      this.isUpld = true;
      this.$http
        .post("/inspection/detail/uploadPic", formData)
        .then((res) => {
          if (res.success) {
            this.handleData.pictureUrl = res.data || "";
          } else {
            this.$message.error("上传失败");
          }
          this.isUpld = false;
        })
        .catch((err) => {
          this.$message.error("上传失败");
          this.isUpld = false;
        });
    },
    onPreview(file) {
      //   console.log(file);
      this.dialogVisible1 = true;
      this.previewSrc = this.handleData.pictureUrl;
    },
    handlePreview(data) {
      this.dialogVisible1 = true;
      this.previewSrc = data.pictureUrl;
    },
    imgUpload(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.isUpld = true;
      this.$http
        .post("/inspection/detail/upload/" + this.handleData.id, formData)
        .then((res) => {
          if (res.success) {
            this.dialogVisible = false;
            this.getData();
          } else {
            this.$message.error("上传失败");
          }
          this.isUpld = false;
        })
        .catch((err) => {
          this.$message.error("上传失败");
          this.isUpld = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.report-main {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .info-item {
    margin-right: 15px;
    padding: 0 20px 20px;
    .label {
      color: #666;
    }
    .value {
      color: #333;
      font-weight: 900;
    }
  }

  .tit-search {
    padding: 20px 20px 0;
  }
  .table-wrapper {
    padding: 0 20px 20px;
    background: #fff;
    height: calc(100% - 110px);
  }
  .picture-cell {
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
    &:hover {
      .mask {
        bottom: 0;
      }
    }
    .mask {
      position: absolute;
      bottom: -51px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: space-around;
      > i {
        font-size: 18px;
        font-weight: 900;
        cursor: pointer;
        color: #fff;
      }
    }
  }
  /deep/ .img-preview {
    .el-dialog__body {
      text-align: center;
    }
  }
}
/deep/ .el-input-number.is-controls-right .el-input__inner{
  text-align: left;
}
</style>
